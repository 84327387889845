<template>
  <main class="container py-2 mx-auto">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-2">

      <div v-for="category in categories" :key="category.prefix" class="flex flex-col gap-2">

        <div :title="category.title ?? category.name">
          <h2 class="text-3xl">Recent {{ category.name }}</h2>
          <NuxtLink :to="category.prefix" class="text-black/75 dark:text-white/75">View All</NuxtLink>
        </div>

        <PostCard v-for="post in content[category.prefix]" :post="post" class="table-cell"/>
      </div>
    </div>
  </main>
</template>
<script lang="ts" setup>
import {ContentType} from "~/lib/vars";
import {useDebugColorMode} from "~/lib/hooks";

useDebugColorMode();

const categories = ref([
  {name: 'Posts', prefix: ContentType.post},
  {name: 'Articles', title: "Knowledgebase Articles", prefix: ContentType.kb}
] as Array<{
  name: string;
  title?: string
  prefix: ContentType;
}>);

const {data: content} = await useAsyncData('home', async () => {
  let [posts, kb] = await Promise.all([ContentType.post, ContentType.kb]
      .map((type) => queryContent(type)
          .where({"createdAt": {"$exists": true}})
          .without(['body'])
          .sort({updatedAt: -1})
          .limit(5)
          .find()
      )
  );

  return {posts, kb};
});

</script>
<style scoped>

</style>
